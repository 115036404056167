import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #EDF2F4;
  color: #ffffff;
  justify-content: flex-end;
`;

const SocialMediaLink = styled.a`
  color: black;
  margin-right: 1rem;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #00a6fb;
    transform: translateY(-2px);
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
        <SocialMediaLink href="https://www.instagram.com/techetez" target="_blank" rel="noreferrer">
          <IconWrapper>
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </IconWrapper>
        </SocialMediaLink>
        <SocialMediaLink href="https://www.facebook.com/profile.php?id=100092432266634" target="_blank" rel="noreferrer">
          <IconWrapper>
            <FontAwesomeIcon icon={faFacebook} size="lg" />
          </IconWrapper>
        </SocialMediaLink>
        <SocialMediaLink href="https://twitter.com/techetez" target="_blank" rel="noreferrer">
          <IconWrapper>
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </IconWrapper>
        </SocialMediaLink>
    </FooterContainer>
  );
};

export default Footer;
