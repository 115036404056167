import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Container = styled.div`
  padding: 1rem;

  /* Mobile-friendly styles */
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const Heading = styled.h2`
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;

  /* Mobile-friendly styles */
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const Terms = () => {
  return (
    <Container>
      <Helmet>
        <title>TECH-ET-EZ - Terms and Conditions</title>
        <meta
          name="description"
          content="Discover the Techetez podcast, where we explore the latest in technology, gadgets, and trends. Stay updated with the latest episodes by subscribing through your preferred platform. Join us as we dive deep into the world of tech and share insights, interviews, and discussions."
        />
      </Helmet>
      <Heading>Terms and Conditions</Heading>
      <Paragraph>
        <strong>1. Eligibility:</strong>
      </Paragraph>
      <List>
        <ListItem>
          The giveaway is open to individuals who are at least 18 years old.
        </ListItem>
        <ListItem>
          Small businesses with fewer than 10 employees are eligible to
          participate.
        </ListItem>
      </List>
      <Paragraph>
        <strong>2. Entry Requirements:</strong>
      </Paragraph>
      <List>
        <ListItem>
          Participants must follow Techetez Podcast's Instagram account
          [@techetez].
        </ListItem>
        <ListItem>
        Share our pin giveaway post on your social media profile.
        </ListItem>
        <ListItem>
          Participants must subscribe to Techetez Podcast on one of the
          following platforms: Spotify, Google Podcasts, or Apple Podcasts.
        </ListItem>
        <ListItem>
          Entries that do not fulfill the above requirements will be
          disqualified.
        </ListItem>
      </List>
      <Paragraph>
        <strong>3. Entry Period:</strong>
      </Paragraph>
      <List>
        <ListItem>
          The giveaway entry period will begin on July 13th, 2023
          and end on July 31st, 2023.
        </ListItem>
        <ListItem>
          Entries submitted outside of the entry period will not be considered.
        </ListItem>
      </List>
      <Paragraph>
        <strong>4. Winner Selection:</strong>
      </Paragraph>
      <List>
        <ListItem>
          One winner will be selected randomly from eligible entries.
        </ListItem>
        <ListItem>
          The winner will receive a prize consisting of a drone photoshoot
          session.
        </ListItem>
        <ListItem>
          The winner will be announced on Techetez Podcast's Instagram account
          [@techetez] and contacted via direct message within 1 day of the
          giveaway's end.
        </ListItem>
      </List>
      <Paragraph>
        <strong>5 Prize Claim:</strong>
      </Paragraph>
      <List>
        <ListItem>
          The winner must respond to the direct message notification within 1
          day to claim their prize.
        </ListItem>
        <ListItem>
          Failure to claim the prize within the specified time will result in
          disqualification, and a new winner may be selected.
        </ListItem>
      </List>
      <Paragraph>
        <strong>6 Prize Delivery:</strong>
      </Paragraph>
      <List>
        <ListItem>
          If an individual winner is selected, the drone photoshoot session must
          be conducted in a public location agreed upon by the winner and
          Techetez Podcast.
        </ListItem>
        <ListItem>
          If a small business winner is selected, the drone photoshoot session
          may take place at the business's location or another agreed-upon
          location.
        </ListItem>
        <ListItem>
          The winner will need to schedule the drone photoshoot session with
          Techetez Podcast within 1 week of claiming the prize.
        </ListItem>
        <ListItem>
          The actual date of the photoshoot will depend on the availability of
          both the winner and Techetez Podcast.
        </ListItem>
        <ListItem>
          The prize, which includes the drone images and aerial footage, will be
          delivered digitally within 2 weeks after the scheduled photoshoot.
        </ListItem>
        <ListItem>
          The winner acknowledges and agrees that any drone footage taken during
          the photoshoot becomes the ownership of Techetez Podcast upon
          delivery. Techetez Podcast assumes full ownership and responsibility
          for the footage and may use, publish, or distribute it for
          promotional, marketing, or other purposes without further
          compensation to the winner. The winner releases Techetez Podcast from
          any liability or claims associated with the ownership, use, or
          distribution of the footage after delivery.
        </ListItem>
      </List>
      <Paragraph>
        <strong>7. Privacy:</strong>
      </Paragraph>
      <List>
        <ListItem>
          By participating in the giveaway, participants acknowledge and agree
          to the collection and use of their personal information for the
          purposes of the giveaway.
        </ListItem>
        <ListItem>
          Personal information collected may include name, email address,
          Instagram handle, and podcast platform subscription details.
        </ListItem>
        <ListItem>
          Techetez Podcast will not share participants' personal information
          with third parties unless required by law.
        </ListItem>
        <ListItem>
          Participants' personal information will be used solely for the purpose
          of administering the giveaway and contacting winners.
        </ListItem>
      </List>
      <Paragraph>
        <strong>8. General Conditions:</strong>
      </Paragraph>
      <List>
        <ListItem>
          Techetez Podcast reserves the right to modify, suspend, or terminate
          the giveaway at any time without prior notice.
        </ListItem>
        <ListItem>
          Techetez Podcast reserves the right to disqualify entries that are
          incomplete, illegible, or violate the terms and conditions.
        </ListItem>
        <ListItem>
          By participating in the giveaway, participants release Techetez
          Podcast from any liability arising out of or in connection with the
          giveaway or the prizes awarded.
        </ListItem>
        <ListItem>
          The giveaway is subject to all applicable laws and regulations.
        </ListItem>
      </List>
      <Paragraph>
        By participating in our giveaway, you acknowledge that you have read and
        understood these terms and conditions.
      </Paragraph>
    </Container>
  );
};

export default Terms;
