import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Subscribe from './Components/Subscribe';
import Display from './Display'
import Drone from './Components/Drone'
import Privacy from './Components/TermsConditions/Privacy'
import Terms from './Components/TermsConditions/Terms'

const AppContainer = styled.div`
  padding: 0 17rem;
  background-color: #EDF2F4;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const FooterContainer = styled.div`
  padding: 1.5rem 0;
`;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <AppContainer>
        <Navbar />
        <Routes>
          <Route path="/" element={<Display />} exact />
          <Route path="/listen" element={<Subscribe />} />
        </Routes>
        <FooterContainer>
          <Footer/>
        </FooterContainer>
      </AppContainer>
    </BrowserRouter>
  );
}

export default App;
