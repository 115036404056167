import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Container = styled.div`
  padding: 1rem;

  /* Mobile-friendly styles */
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const Heading = styled.h2`
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;

  /* Mobile-friendly styles */
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const Privacy = () => {
  return (
    <Container>
      <Helmet>
        <title>TECH-ET-EZ - Privacy Policy</title>
        <meta
          name="description"
          content="Discover the Techetez podcast, where we explore the latest in technology, gadgets, and trends. Stay updated with the latest episodes by subscribing through your preferred platform. Join us as we dive deep into the world of tech and share insights, interviews, and discussions."
        />
      </Helmet>
      <Heading>Privacy Policy</Heading>
      <Paragraph>
        At Techetez Podcast, we are committed to protecting your privacy. This
        privacy policy explains how we collect, use, and safeguard your personal
        information.
      </Paragraph>
      <Paragraph>
        <strong>1. Information We Collect:</strong>
      </Paragraph>
      <List>
        <ListItem>
          When you participate in our giveaway, we may collect the following
          personal information: name, email address, Instagram handle, and
          podcast platform subscription details.
        </ListItem>
      </List>
      <Paragraph>
        <strong>2. Use of Information:</strong>
      </Paragraph>
      <List>
        <ListItem>
          We use the collected information solely for the purpose of
          administering the giveaway, contacting winners, and delivering the
          prizes.
        </ListItem>
        <ListItem>
          We may also use your email address to send you updates about our
          podcast, future giveaways, and related content, provided you have
          given your consent to receive such communications.
        </ListItem>
      </List>
      <Paragraph>
        <strong>3. Data Security:</strong>
      </Paragraph>
      <List>
        <ListItem>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction.
        </ListItem>
        <ListItem>
          However, please note that no method of transmission over the internet
          or electronic storage is 100% secure, and we cannot guarantee absolute
          security of your information.
        </ListItem>
      </List>
      <Paragraph>
        <strong>4. Sharing of Information:</strong>
      </Paragraph>
      <List>
        <ListItem>
          We do not sell, trade, or transfer your personal information to third
          parties unless required by law.
        </ListItem>
        <ListItem>
          We may share your personal information with service providers who
          assist us in administering the giveaway and delivering the prizes, but
          they are obligated to keep your information confidential.
        </ListItem>
      </List>
      <Paragraph>
        <strong>5. Children's Privacy:</strong>
      </Paragraph>
      <List>
        <ListItem>
          Our giveaway is not intended for individuals under the age of 18. We
          do not knowingly collect personal information from individuals under
          18 years old.
        </ListItem>
      </List>
      <Paragraph>
        <strong>6. Changes to the Privacy Policy:</strong>
      </Paragraph>
      <List>
        <ListItem>
          We reserve the right to update or modify this privacy policy at any
          time. Any changes will be effective immediately upon posting the
          revised policy on our website.
        </ListItem>
      </List>
      <Paragraph>
        <strong>7. Contact Us:</strong>
      </Paragraph>
      <List>
        <ListItem>
          If you have any questions or concerns about our privacy policy or the
          giveaway, please contact us at [hola@latinosin.tech].
        </ListItem>
      </List>
      <Paragraph>
        By participating in our giveaway, you acknowledge that you have read and
        understood this privacy policy and agree to the collection, use, and
        disclosure of your personal information as described herein.
      </Paragraph>
    </Container>
  );
};

export default Privacy;
