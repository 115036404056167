import React from "react";
import styled from "styled-components";
import podcastImage from "../assets/techetez-logo.png";
import { Helmet } from "react-helmet";
import googleButtonImage from "../assets/ListenButtons/google-podcasts.png";
import appleButtonImage from "../assets/ListenButtons/apple-podcasts_1.png";
import spotifyButtonImage from "../assets/ListenButtons/Spotify button.png";

const ContentContainer = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  // background-color: purple;
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const SubscribeContainer = styled.div`
  color: black;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgb(176, 175, 175);
  background-color: #f2f2f2;
  width: 95%;
  border: 1px solid;
  border-color: black;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 350px;
  height: 350px;
  object-fit: cover;
  margin-bottom: 2rem;
  border-radius: 5px;
  box-shadow: 3px 3px 3px #2b2a2c;
  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

const ListenButton = styled.img`
  height: 4.5rem;
  display: block;
  margin: 1rem;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-out;
  }
  @media (max-width: 768px) {
    height: 3.5rem;
  }
`;

const GoogleButton = styled(ListenButton)`
  @media (max-width: 768px) {
    margin-left: 2rem;
  }
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Subscribe = () => {
  return (
    <ContentContainer>
      <table>
        <tr>
          <td>
            <Helmet>
              <title>TECH-ET-EZ - Listen</title>
              <meta
                name="description"
                content="Discover the Techetez podcast, where we explore the latest in technology, gadgets, and trends. Stay updated with the latest episodes by subscribing through your preferred platform. Join us as we dive deep into the world of tech and share insights, interviews, and discussions."
              />
            </Helmet>
          </td>
          <td>
            <SubscribeContainer>
              <Image src={podcastImage} alt="TECH-ET-EZ Podcast Logo" />
              <BubbleContainer>
                <a
                  href="https://bit.ly/3WE7Cdy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GoogleButton src={googleButtonImage} />
                </a>
                <a
                  href="https://spoti.fi/3C07yvc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ListenButton src={spotifyButtonImage} />
                </a>
                <a
                  href="https://apple.co/3OIM2Tj"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ListenButton src={appleButtonImage} />
                </a>
              </BubbleContainer>
            </SubscribeContainer>
          </td>
        </tr>
      </table>
    </ContentContainer>
  );
};

export default Subscribe;
