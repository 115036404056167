import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const DroneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
`;

const Title = styled.h2`
  margin-top: 1rem;
  margin-right: 1rem;
`;

const GiveawayDates = styled.h3`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  justify-content: center;
  font-weight: normal;
`;

const StyledLink = styled(RouterLink)`
  color: #007bff;
  text-decoration: underline;
`;

const GiveawayLink = styled(StyledLink)`
  text-decoration: none;
  color: blue;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #764ca1;
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 3rem;

  /* Mobile-friendly styles */
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  margin-top: 0rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
`;

const ListItem = styled.li`
  margin-top: 0.5rem;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  padding: 1.5rem;
  width: 450px; /* Fixed width value */

  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 4px 3px 3px rgb(176, 175, 175);

  /* Mobile-friendly styles */
  @media (max-width: 768px) {
    width: 100%;
    padding: 1.5rem 2rem;
  }
`;

const StepCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #764ca1;
  color: white;
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

const StepTitle = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const StepDescription = styled.p`
  font-size: 0.875rem;
  text-align: center;
  margin-top: 0.5rem;
`;

const InstagramTag = styled.a`
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  margin-top:0.5rem;
  display: block;

  &:hover {
    color: #764ca1;
  }
`;

const Drone = () => {
  return (
    <DroneContainer>
      <Helmet>
        <title>TECH-ET-EZ - Giveaway</title>
        <meta
          name="description"
          content="Discover the Techetez podcast, where we explore the latest in technology, gadgets, and trends. Stay updated with the latest episodes by subscribing through your preferred platform. Join us as we dive deep into the world of tech and share insights, interviews, and discussions."
        />
      </Helmet>
      <Title>How to Enter:</Title>
      <GiveawayDates>July 14th, 2023 - July 31st, 2023</GiveawayDates>
      <StepContainer>
        <StepCircle>1</StepCircle>
        <StepTitle>Follow us on Instagram</StepTitle>
        <StepDescription>
          Follow our Instagram account for updates and announcements.
          <InstagramTag
            href="https://www.instagram.com/techetez/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faInstagram} /> @techetez
          </InstagramTag>
        </StepDescription>
      </StepContainer>
      <StepContainer>
        <StepCircle>2</StepCircle>
        <StepTitle>Share the giveaway post</StepTitle>
        <StepDescription>
        Help spread the word by sharing our Pin Giveaway post on Instagram!
        </StepDescription>
      </StepContainer>
      <StepContainer>
        <StepCircle>3</StepCircle>
        <StepTitle>Follow us on your preferred podcast platform</StepTitle>
        <List>
          <ListItem>
            <StyledLink target="_blank" to="https://spoti.fi/3C07yvc">
              Spotify
            </StyledLink>
          </ListItem>
          <ListItem>
            <StyledLink target="_blank" to="https://bit.ly/3WE7Cdy">
              Google Podcasts
            </StyledLink>
          </ListItem>
          <ListItem>
            <StyledLink target="_blank" to="https://apple.co/3OIM2Tj">
              Apple Podcasts
            </StyledLink>
          </ListItem>
        </List>
      </StepContainer>
      <Paragraph>
        By participating in this giveaway, you agree to our{" "}
        <GiveawayLink to="/terms">Terms and Conditions</GiveawayLink> and{" "}
        <GiveawayLink to="/privacy">Privacy Policy</GiveawayLink>.
      </Paragraph>
    </DroneContainer>
  );
};

export default Drone;
