import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import logoImage from '../assets/techetez-logo.png';

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #EDF2F4;
  color: #000000;

  @media (max-width: 768px) {
    padding: 1rem .6rem;
  }
`;

const Logo = styled.img`
  height: 40px;
  border-radius: 4px;
  width: auto;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

const LogoCon = styled(Link)``;

const Navbar = () => {
  return (
    <NavContainer>
      <LogoCon to="/">
        <Logo src={logoImage} alt="TECH-ET-EZ Podcast Logo" />
      </LogoCon>
      🌎
    </NavContainer>
  );
};

export default Navbar;
