import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import PodcastData from './PodcastData'
import {Helmet} from "react-helmet";

const PlaylistContainer = styled.div`
  padding: 2rem;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 0 .8rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    align-items: start;
    flex-direction: column;
  }
`;

const Title = styled.h2`
  margin: 0;
  margin-right: 10px;
`;

const SubTitle = styled.h2`
  margin: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #764ca1;

  @media (max-width: 768px) {
    margin-top:.5rem;

  }
`;

const PlaylistItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  background: rgb(51,19,84);
  background: linear-gradient(90deg, rgba(51,19,84,1) 0%, rgba(142,122,162,1) 98%);
  color: #fff;
  padding: 1.1rem;
  border-radius: 5px;
  box-shadow: 4px 3px 3px rgb(176, 175, 175);
  &:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const EpisodeImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 5px;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const EpisodeContent = styled.div`
  flex-grow: 1;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const EpisodeTitle = styled.h3`
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const Tags = styled.button`
  background: linear-gradient(to right, #00a6fb, #0072ff);
  color: #ffffff;
  padding: 0.4rem;
  border: none;
  border-radius: 4px;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const EpisodeDescription = styled.p`
  font-size: 1rem;
  margin: 0;
`;


const PlayButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: ${({ isPlaying }) => (isPlaying ? "white" : "black")};
  color: ${({ isPlaying }) => (isPlaying ? "black" : "white")};
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  text-decoration: none;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover {
    background-color: ${({ isPlaying }) => (isPlaying ? "black" : "white")};
    color: ${({ isPlaying }) => (isPlaying ? "white" : "black")};
  }

  &:focus {
    outline: none;
  }
`;

const Separator = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  margin: 1rem 0;
`;

const truncateText = (text, limit) => {
  const words = text.split(" ");
  if (words.length > limit) {
    return words.slice(0, limit).join(" ") + "...";
  }
  return text;
};

const PodcastPlaylist = () => {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = (audioUrl) => {
    const cookieName = 'PlaybackCookie';
    const cookieValue = 'audio1';
  
    document.cookie = `${cookieName}=${cookieValue}; SameSite=None; Secure`;

    if (currentAudio === audioUrl) {
      setIsPlaying((prevState) => !prevState);
    } else {
      setCurrentAudio(audioUrl);
      setIsPlaying(true);
    }
  };

  const handlePauseClick = () => {
    setCurrentAudio(null);
    setIsPlaying(false);
  };

  const getPlayButtonIcon = (audioUrl) => {
    if (audioUrl === currentAudio) {
      return isPlaying ? faPause : faPlay;
    } else {
      return faPlay;
    }
  };

  return (
    <PlaylistContainer>
      <Helmet>
      <title>TECH-ET-EZ Podcast - Explore Tech Trends and Innovations</title>
        <meta
          name="description"
          content="Discover the Techetez podcast, where we explore the latest in technology, gadgets, and trends. Stay updated with the latest episodes by subscribing through your preferred platform. Join us as we dive deep into the world of tech and share insights, interviews, and discussions."
        />
      </Helmet>
      <TitleContainer>
        <Title>Latest Episodes</Title>
        {/* <SubTitle>[Every Tuesday & Thursday]</SubTitle> */}
      </TitleContainer>
      <Separator />
      {PodcastData.map((item, index) => (
        <React.Fragment key={index}>
          <PlaylistItem>
            <EpisodeImage
              src={item.episodeImage}
              alt="TECH-ET-EZ Podcast Episode Image"
            />
            <EpisodeContent>
              <EpisodeTitle>{item.episodeTitle}</EpisodeTitle>
              {item.tags &&
                Array.isArray(item.tags) &&
                item.tags.map((tag, index) => <Tags key={index}>{tag}</Tags>)}
              <EpisodeDescription>
                {truncateText(item.episodeDescription, 16)}
              </EpisodeDescription>
            </EpisodeContent>
            {item.audioUrl && (
              <React.Fragment>
                {item.audioUrl === currentAudio && isPlaying ? (
                  <PlayButton
                    isPlaying={item.audioUrl === currentAudio && isPlaying}
                    onClick={handlePauseClick}
                  >
                    <FontAwesomeIcon icon={faPause} />
                  </PlayButton>
                ) : (
                  <PlayButton onClick={() => handlePlayClick(item.audioUrl)}>
                    <FontAwesomeIcon icon={getPlayButtonIcon(item.audioUrl)} />
                  </PlayButton>
                )}
              </React.Fragment>
            )}
            {item.audioUrl === currentAudio && (
              <audio src={item.audioUrl} autoPlay />
            )}
          </PlaylistItem>
          <Separator />
        </React.Fragment>
      ))}
    </PlaylistContainer>
  );
};

export default PodcastPlaylist;
