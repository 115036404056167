import React from "react";
import styled from "styled-components";
import HeaderContainer from "./Components/HeaderContainer";
import PodcastPlaylist from "./Components/PodcastPlaylist";
import DateBanner from "./assets/2-engineers.png";
import appleButtonImage from "./assets/ListenButtons/apple-podcasts_1.png";
import spotifyButtonImage from "./assets/ListenButtons/Spotify button.png";

const AppContainer = styled.div``;

const ContentContainer = styled.div`
  padding-top: 1rem;
`;

const BannerContainerDate = styled.div`
  width: 100%;
  height: 180px;
  background-image: url(${DateBanner});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 4px 3px 3px rgb(176, 175, 175);

  /* Mobile-friendly styles */
  @media (max-width: 768px) {
    height: 100px;
    background-image: url(${DateBanner});
  }
`;

const SubscribeContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ListenButton = styled.img`
  height: 3rem;
  max-width: 100%;
  margin-right: 1rem;
  margin-top: 0rem;
  display: block;

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-out;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    margin-right: 0rem;
    height: 2.5rem;
  }
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

function Display() {
  return (
    <AppContainer>
      <ContentContainer>
        <HeaderContainer />
        <SubscribeContainer>
          <BubbleContainer>
            <a href="https://spoti.fi/3C07yvc" target="_blank" rel="noreferrer">
              <ListenButton src={spotifyButtonImage} />
            </a>
            <a href="https://apple.co/3OIM2Tj" target="_blank" rel="noreferrer">
              <ListenButton src={appleButtonImage} />
            </a>
          </BubbleContainer>
        </SubscribeContainer>
        {/* <SponsorBanner /> */}
      </ContentContainer>
      <ContentContainer>
        <BannerContainerDate />
      </ContentContainer>
      <ContentContainer>
        <PodcastPlaylist />
      </ContentContainer>
      {/* <ContentContainer>
      // init branch
        <Host />
      </ContentContainer> */}
    </AppContainer>
  );
}

export default Display;
