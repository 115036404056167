import logoImage from "../assets/techetez-logo.png";

const playlistItems = [
  {
    episodeImage: logoImage,
    episodeTitle: "Holiday Tech Especial - Part 1",
    tags: ["New", "Dec 27 • 19:18s", "Preview",],
    episodeDescription: "In Part 1 of this series, our hosts discuss their favorite Christmas drinks, forecast developments in AI and Web 3 for 2024, and explore the increase in misinformation and its potential impact on upcoming elections.",
    audioUrl: "https://techetez-podcast.s3.us-east-2.amazonaws.com/episode-39-part-1.mp3"
  },
  {
    episodeImage: logoImage,
    episodeTitle: "Bad Bunny vs AI Bad Bunny",
    tags: ["Dec 13 • 11:40s", "Preview",],
    episodeDescription: "Experience the ultimate face-off as the real Bad Bunny and AI Bad Bunny collide in a battle of creativity. ",
    audioUrl: "https://techetez-podcast.s3.us-east-2.amazonaws.com/episode-38-preview.mp3"
  },
  {
    episodeImage: logoImage,
    episodeTitle: "Edge Computing, Data Leaks, and Milwaukee Roundabouts",
    tags: ["Dec 5 • 19:43s", "Preview",],
    episodeDescription: "Let's Talk About the Future and Data Security in the Age of Data Leaks, Along with Carlos's Observations on the Increasing Use of Roundabouts",
    audioUrl: "https://techetez-podcast.s3.us-east-2.amazonaws.com/episode-37-preview.mp3"
  },
  {
    episodeImage: logoImage,
    episodeTitle: "Coffee, Pan dulce & Artificial Intelligence",
    tags: ["Dec 1 • 19:56s", "Preview",],
    episodeDescription: "We sip on coffee made by Ben and pan dulce from a local bakery, engaging in a conversation about artificial intelligence.",
    audioUrl: "https://techetez-podcast.s3.us-east-2.amazonaws.com/episode-36-preview.mp3"
  }
];

export default playlistItems;