import React from 'react';
import './HeaderContainer.css'


const HeaderContainer = () => {
  return (
  <div className="body">
    <div className="content">
      <h2 className="text_shadows">TECH-ET-EZ</h2>
      <h3 className='sub-text'>PODCAST</h3>
    </div>
  </div>
  )
}

export default HeaderContainer;